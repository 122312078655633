<template>
  <v-expansion-panels v-model="expansionPanel">
    <v-expansion-panel value="all">
      <v-expansion-panel-title
        v-if="!hideTitle"
        v-slot="{ expanded }"
        :class="{ 'not-expandable': !isExpandable }"
        :focusable="isExpandable"
        :hide-actions="!isExpandable"
        :readonly="!isExpandable"
        :static="!isExpandable"
        class="v-expansion-title px-5 align-baseline"
      >
        <div class="pb-4 w-100">
          <v-row
            class="d-flex justify-start px-3"
            no-gutters
          >
            <v-col>
              <v-row class="align-center">
                <div class="fs-18 fw-600">
                  {{ getName(householdMember) }}
                </div>
                <v-chip
                  v-if="managedSubsidyProgramEligibilityDetermination?.owner_name"
                  :color="eligibilityIconColor"
                  class="ms-13 badge-style justify-center"
                  variant="flat"
                  rounded
                >
                  <v-icon
                    color="white mr-2"
                    role="presentation"
                    aria-hidden
                  >
                    {{ eligibilityIcon }}
                  </v-icon>
                  <p class="font-weight-bold">
                    {{ projectedEligibility ? $t('Eligible') : $t('Not eligible') }}
                  </p>
                </v-chip>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="!expanded">
            <v-col cols="12">
              <HouseholdMemberDetails
                :can-edit="false"
                :household-member="householdMember"
              />
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="px-5">
        <template v-if="editing || locked">
          <div class="mb-4">
            <HouseholdMemberFields
              @cancel="cancel"
              @household-member-changed="householdMemberUpdated"
              @remove="remove"
              @save="update"
              :autofocus="autofocus"
              :edit-mode="true"
              :hard-lock="locked"
              :household-member-values="value"
              :processing="processing"
              :self-exists="selfExists"
              :spouse-exists="spouseExists"
            />
          </div>
        </template>
        <template v-else>
          <HouseholdMemberDetails
            @edit="$emit('edit', $event)"
            :can-edit="canEdit"
            :household-member="householdMember"
          />
        </template>

        <div v-if="enableRelatedSubsidies">
          <div v-if="managedSubsidyProgramEligibilityDetermination">
            <v-divider class="my-6" />
            <div class="fs-16 fw-600 mb-4">
              <div>{{ managedSubsidyProgramEligibilityDetermination.owner_name }}</div>
            </div>

            <v-row class="d-flex justify-between">
              <SubsidyEligibilityCardV2
                :funding-sources="
                  managedSubsidyProgramEligibilityDetermination.eligibility_determinations
                "
                :projected-eligibility="
                  managedSubsidyProgramEligibilityDetermination.projected_eligibility
                "
              />
              <div
                v-if="managedChildSubsidy"
                class="mx-10 d-flex justify-end"
              >
                <v-btn
                  @click="
                    reviewSubsidyForProgramId(
                      managedSubsidyProgramEligibilityDetermination.owner_id,
                    )
                  "
                  class="me-3"
                  color="primary"
                  variant="flat"
                >
                  {{ $t('Review') }}
                </v-btn>
              </div>
              <div
                v-else
                class="mx-3 d-flex justify-end"
              >
                <v-btn
                  @click="createSubsidy(managedSubsidyProgramEligibilityDetermination.owner_id)"
                  class="me-3"
                  color="primary"
                  variant="flat"
                >
                  {{ $t('Enroll') }}
                </v-btn>
              </div>
            </v-row>
          </div>
          <div
            v-if="otherSubsidyProgramEligibilityDetermination.length > 0"
            class="fs-16 fw-600 mb-4 mt-6"
          >
            Other subsidy programs
          </div>
          <div
            v-for="otherResult in otherSubsidyProgramEligibilityDetermination"
            :key="otherResult.owner_id"
            class="mx-6"
          >
            <div class="fs-14 fw-600 mt-6">
              {{ otherResult.owner_name }} eligibility
              <v-row class="d-flex justify-between">
                <SubsidyEligibilityCardV2
                  :funding-sources="otherResult.eligibility_determinations"
                  :projected-eligibility="otherResult.projected_eligibility"
                />
                <div v-if="subsidyForOwnerId(otherResult.owner_id)">
                  <v-btn
                    @click="reviewSubsidyForProgramId(otherResult.owner_id)"
                    class="me-3 fs-14"
                    color="primary"
                    size="small"
                    variant="outlined"
                  >
                    {{ $t('Review') }}
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    @click="createSubsidy(otherResult.owner_id)"
                    class="me-3 fs-14"
                    color="primary"
                    size="small"
                    variant="outlined"
                  >
                    {{ $t('Enroll') }}
                  </v-btn>
                </div>
              </v-row>
            </div>
          </div>
        </div>
        <v-divider
          v-if="householdMemberQuestionRegister[householdMember.id]"
          class="mt-10 mb-6"
        />
        <QuestionSet
          v-if="householdMemberQuestionRegister[householdMember.id]"
          @change="changed = true"
          @change:attachments="loadAttachments()"
          :attachment-group-id="subsidy.group_id"
          :attachment-owner-id="subsidy.id"
          :attachment-owner-type="'FamilySubsidy'"
          :attachment-tags-supplements="[householdMember.id]"
          :attachments="attachments"
          :color="null"
          :key-name="householdMember.id"
          :model-value="householdMember"
          :processing="processing"
          :questions="
            getHouseholdMemberQuestions(householdMember).filter((question) => question.valid)
          "
          :schema="householdMemberSchema.definition"
          class="mb-6"
          condensed
          dense
          divided
          expanded
          hide-actions
          readonly
          tile
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Api from '@/shared/services/bright_finder';
import HouseholdMemberFields from '@/shared/components/household-members/HouseholdMemberFields.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import HouseholdMemberDetails from '@/shared/components/household-members/HouseholdMemberDetails.vue';
import SubsidyEligibilityCardV2 from '@/shared/components/subsidy/SubsidyEligibilityCardV2.vue';

const eventBus = useEventBus();
const router = useRouter();

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  editing: {
    type: Boolean,
    default: false,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
  householdMemberApi: {
    type: Object,
    default: () => Api.parent.household_member,
  },
  householdMemberQuestionRegister: {
    type: Object,
    default: () => ({}),
  },
  householdMemberSchema: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: null,
  },
  isExpandable: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  managedSubsidyProgramId: {
    type: String,
    default: null,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  preventUpdate: {
    type: Boolean,
    default: false,
  },
  selfExists: {
    default: false,
    type: Boolean,
  },
  spouseExists: {
    default: false,
    type: Boolean,
  },
  staged: {
    default: false,
    type: Boolean,
  },
  subsidy: {
    type: Object,
    default: () => ({}),
  },
  subsidyApi: {
    type: Object,
    default: () => Api.parent.subsidy,
  },
  value: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['cancel', 'change', 'edit', 'removal', 'save']);

const attachments = ref([]);
const expansionPanel = ref(props.isExpandable ? [] : ['all']);
const fundingSources = ref([]);
const householdMember = ref(getCopiedValue()); // TODO: Can we receive householdMember via defineModel and not use getCopiedValue?
const processing = ref(false);
const projectedEligibility = ref(false);
const eligibilityDeterminations = ref([]);
const managedChildSubsidy = ref({});
const managedSubsidyProgramEligibilityDetermination = ref({});
const otherSubsidyProgramEligibilityDetermination = ref({});
const subsidies = ref([]);

const eligibilityIcon = computed(() => {
  return projectedEligibility.value ? 'check_circle' : 'cancel';
});

const eligibilityIconColor = computed(() => {
  return projectedEligibility.value ? 'green-500' : 'red';
});

const enableRelatedSubsidies = computed(() => {
  return householdMember.value.dob && props.managedSubsidyProgramId;
});

function cancel() {
  householdMember.value = getCopiedValue();
  emit('cancel', householdMember.value);
}

function getHouseholdMemberQuestions(householdMember) {
  if (props.householdMemberQuestionRegister.length === 0) return [];

  return props.householdMemberQuestionRegister[householdMember.id];
}

function getName(householdMember) {
  const { first_name: firstName, middle_name: middleName, last_name: lastName } = householdMember;

  return [firstName, middleName, lastName].join(' ');
}

function householdMemberUpdated(newVal) {
  householdMember.value = newVal;
}

function getCopiedValue() {
  return JSON.parse(JSON.stringify(props.value));
}

async function remove() {
  if (props.staged) return;

  await props.householdMemberApi.destroy(householdMember.value.id);
  emit('removal', householdMember.value);
}

async function update() {
  if (props.staged) emit('save', householdMember.value);
  if (props.preventUpdate) return emit('change', householdMember.value);

  processing.value = true;
  const resp = await props.householdMemberApi
    .update(householdMember.value)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (resp?.status !== 200) return null;

  emit('save', householdMember.value);
  householdMember.value = resp.data;
  return emit('change', householdMember.value);
}

async function loadAttachments() {
  processing.value = true;
  const params = {
    owner_type: 'FamilySubsidy',
    owner_id: props.subsidy?.id,
  };

  const resp = await Api.member.attachment.index(params);
  processing.value = false;

  if (resp?.status === 200) attachments.value = resp.data;
}

async function loadSubsidyProgramsAndEligibility() {
  if (!enableRelatedSubsidies.value) return;

  const subsidiesResponse = await props.subsidyApi.index({
    household_member_id: householdMember.value.id,
  });
  if (subsidiesResponse.data) subsidies.value = subsidiesResponse.data;
  managedChildSubsidy.value = subsidyForOwnerId(props.managedSubsidyProgramId);

  const eligibilitiesResponse = await Api.public_api.eligibilityDeterminations.create({
    all_open_subsidy_programs: true,
    application_data: {
      family_data: props.subsidy,
      individual_data: householdMember.value,
    },
  });
  eligibilityDeterminations.value = eligibilitiesResponse.data;
  managedSubsidyProgramEligibilityDetermination.value = eligibilityDeterminations.value.find(
    (result) => result.owner_id === props.managedSubsidyProgramId,
  );
  otherSubsidyProgramEligibilityDetermination.value = eligibilityDeterminations.value.filter(
    (result) => result.owner_id !== props.managedSubsidyProgramId,
  );
  if (managedSubsidyProgramEligibilityDetermination.value) {
    projectedEligibility.value =
      managedSubsidyProgramEligibilityDetermination.value.projected_eligibility;
    fundingSources.value =
      managedSubsidyProgramEligibilityDetermination.value.eligibility_determinations;
  }
}

function subsidyForOwnerId(ownerId) {
  return subsidies.value.find((subsidy) => subsidy.subsidy_program_id === ownerId);
}

async function createSubsidy(subsidyProgramId) {
  const subsidyResponse = await props.subsidyApi.create({
    family_subsidy_id: props.subsidy.id,
    household_member_id: householdMember.value.id,
    subsidy_program_id: subsidyProgramId,
  });
  if (subsidyResponse.data) {
    await router.push({
      name: 'SubsidyShow',
      query: { tab: 'Enrollment' },
      params: { id: subsidyResponse.data.id },
    });
  }
}

async function reviewSubsidyForProgramId(subsidyProgramId) {
  const subsidyId = subsidyForOwnerId(subsidyProgramId).id;
  await router.push({
    name: 'SubsidyShow',
    query: { tab: 'Enrollment' },
    params: { id: subsidyId },
  });
}

watch(
  householdMember,
  async () => {
    await loadSubsidyProgramsAndEligibility();
    await loadAttachments();
  },
  { immediate: true },
);
</script>
