<template>
  <div class="fill-height bg-super-light-blue d-flex">
    <div
      :style="providerClass"
      class="va-top"
    >
      <div
        v-if="schema"
        class="pa-3"
      >
        <ProviderMatch
          v-if="selectedMatch && selectedMatch && resultsMode == 'provider'"
          @close="closeProvider()"
          @favorite:add="$emit('favorite:add', $event)"
          @favorite:list="$emit('favorite:list', $event)"
          @favorite:remove="$emit('favorite:remove', $event)"
          @refresh="$emit('refresh', selectedMatch.id)"
          :favorites="favorites"
          :lists="lists"
          :match="selectedMatch"
          :schema="schema"
          :search="search"
        />
      </div>
    </div>

    <div
      id="scrollable_results"
      :style="listStyle"
      class="va-top focus-very-visible"
    >
      <div
        v-if="$store.state.pages.Search.support_alert"
        class="px-4"
      >
        <h1>
          <MarkdownContent :content="$store.state.pages.Search.support_alert" />
        </h1>
      </div>

      <div
        v-if="showStructuredSupportAlert"
        class="px-4 pt-3 pb-2"
      >
        <StructuredText :text="$store.state.pages.Search.structured_support_alert" />
      </div>

      <div
        v-if="
          $store.state.pages.Search.program_alert && $store.state.pages.Search.program_alert.url
        "
        class="px-3"
      >
        <ProgramAlert />
      </div>

      <v-card
        v-show="!processing && matches.length == 0"
        class="elevation-0 mx-3 bg-none mt-6"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <p
                ref="noMatch"
                class="focus-lightly-visible fs-16 fw-500 pa-4 ta-center"
                tabindex="0"
              >
                {{
                  $t(
                    search.id
                      ? 'No providers matched your search with the filters you have. Try expanding your filters to see more results.'
                      : 'Apply a filter above to begin a search',
                  )
                }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img
                :src="$a.url('icons.nullfilters')"
                alt="No match"
                max-height="300px"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-show="processing"
        class="elevation-0 mx-3"
      >
        <v-card-text>
          <v-row class="mt-6">
            <v-col>
              <v-progress-linear
                indeterminate
                stream
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <template v-if="schema">
        <div
          v-for="(match, index) in matches"
          :id="'result_link_' + match.id"
          :key="match.id"
          class="px-3 pt-3"
          tracked
        >
          <v-card
            ref="match"
            :ripple="false"
            class="pa-0"
            border
            flat
            tile
          >
            <v-card-text>
              <MatchListing
                @favorite:add="$emit('favorite:add', $event)"
                @favorite:list="$emit('favorite:list', $event)"
                @favorite:remove="$emit('favorite:remove', $event)"
                @mouseover="selectedIndex = index"
                :id="match.id"
                :key="match.id"
                ref="matchListing"
                :favorites="favorites"
                :lists="lists"
                :match="match"
                :schema="schema"
                :search="search"
              />
            </v-card-text>
            <v-card-actions
              v-if="$store.state.pages.Search.features.enable_financial_aid_calculator"
              @click="openCalculator"
              class="bt-1 pt-3 bc-extra-light-grey"
            >
              <TuitionCredit
                :inputs="calculatorInputs"
                :provider="match"
              />
            </v-card-actions>
            <v-card-actions
              @click="select(index)"
              @keyup.enter="select(index)"
              class="bt-1 pt-3 bc-extra-light-grey pointer"
              tabindex="0"
            >
              <div
                class="fs-14 fw-400"
                data-cy="match-view-more"
              >
                {{ $t('View more information') }}
              </div>
              <v-spacer />
              <v-icon
                class="fs-22 fw-500"
                icon="chevron_right"
              />
            </v-card-actions>
          </v-card>
        </div>
      </template>

      <ResourceDialog
        @save="calculateFinancialAid"
        ref="calculatorDialog"
        :fields="calculatorFields"
        :max-width="600"
        save-button-text="Estimate tuition credit"
        title="Estimate my DPP Tuition Credit"
      >
        <template #description>
          <p class="c-light-black fs-14">
            {{
              $t(`*Complete the form below to see the potential tuition credit your family may be eligible \
to receive. DPP tuition credits are currently available for all 4-year-olds and select 3-year-olds who reside \
in the City or County of Denver. Family size = The total number of people who live in your household, including \
yourself and your 3 or 4-year-old child. Gross Annual Income = The total amount of income you have earned in a year \
from all sources before taxes.`)
            }}
          </p>
        </template>
      </ResourceDialog>

      <VerticalSpacer />

      <v-card
        v-show="!processing && pages > 1"
        class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
        tile
      >
        <v-pagination
          @update:model-value="$emit('change:page', $event)"
          :length="pages"
          :model-value="page"
          :total-visible="8"
          class="custom"
          rounded
        />
      </v-card>
    </div>

    <div
      :style="mapStyle"
      class="focus-very-visible va-top"
      style="position: relative"
    >
      <div :style="redoSearchButtonStyle">
        <v-btn
          @click="redoSearch()"
          id="redo_link"
          :class="showRedoSearchHere ? 'bg-white' : 'bg-transparent'"
          :disabled="mapChangedProcessing || !showRedoSearchHere"
          :ripple="false"
          :variant="showRedoSearchHere ? 'flat' : 'tonal'"
          rounded
        >
          <v-progress-circular
            v-show="mapChangedProcessing"
            class="me-2"
            color="gray"
            indeterminate=""
            size="15"
          />
          <span>{{ $t('Redo search here') }}</span>
        </v-btn>
      </div>

      <GoogleMap
        @center_changed="handleMapChanged"
        @zoom_changed="handleMapChanged"
        ref="mapRef"
        :api-promise="apiPromise"
        :center="mapCenter"
        :disable-default-ui="true"
        :scale-control="true"
        :zoom="zoom"
        gesture-handling="greedy"
        style="width: 100%; height: 100%"
        zoom-control
      >
        <GoogleMapMarker
          v-if="mapOrigin"
          :options="{
            position: mapOrigin,
            icon: search.location_type == 'home' ? $a.url('icons.home') : $a.url('icons.business'),
          }"
        />
        <GoogleMapMarker
          v-if="mapDestination && search.location_type == 'commute'"
          :options="{
            position: mapDestination,
            icon: $a.url('icons.business'),
          }"
        />
        <MatchMarker
          v-for="(match, index) in matches"
          @click="select(index)"
          :key="match.id"
          :match="match"
          :selected="match == selectedMatch"
        />
      </GoogleMap>
    </div>

    <v-btn
      @click="changeMode(displayListFab ? 'map' : 'list')"
      :ripple="false"
      :style="{
        position: 'fixed',
        bottom: '30px',
        right: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '150px',
      }"
      class="d-md-none"
      color="primary"
      elevation="1"
      rounded
    >
      {{ $t(displayListFab ? 'Map view' : 'List view') }}
    </v-btn>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useDisplay, useRtl } from 'vuetify';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MatchListing from '@/shared/components/search/MatchListing.vue';
import MatchMarker from '@/shared/components/map/MatchMarker.vue';
import ProgramAlert from '@/shared/components/search/ProgramAlert.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import StructuredText from '@/shared/components/content/StructuredText.vue';
import TuitionCredit from '@/shared/components/TuitionCredit.vue';
import { Coord } from '@/shared/services/coord';
import { RouteDispatcher } from '@/shared/services/route_dispatcher';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { GoogleMap, Marker as GoogleMapMarker } from 'vue3-google-map';
import { Loader as GoogleMapLoader } from '@googlemaps/js-api-loader';

const store = useStore();
const display = useDisplay();
const { isRtl } = useRtl();

const props = defineProps({
  defaultCenter: {
    type: Object,
    default: null,
  },
  favorites: {
    type: Array,
    default: null,
  },
  lists: {
    type: Array,
    default: null,
  },
  loaded: {
    type: Boolean,
    default: null,
  },
  matches: {
    type: Array,
    default: null,
  },
  page: {
    type: Number,
    default: null,
  },
  pages: {
    type: Number,
    default: null,
  },
  processing: {
    type: Boolean,
    default: null,
  },
  search: {
    type: Object,
    default: null,
  },
  top: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits([
  'change:page',
  'favorite:add',
  'favorite:list',
  'favorite:remove',
  'redo',
  'refresh',
]);

const loader = new GoogleMapLoader({
  apiKey: import.meta.env.VUE_APP_MAPS_KEY,
  version: 'weekly',
  libraries: ['places'],
});

const apiPromise = loader.load();

const calculatorFields = ref([
  {
    cols: '6',
    text: 'Family size',
    value: 'family_size',
    items: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    cols: '6',
    text: 'Gross annual income',
    type: 'currency',
    value: 'income',
  },
  {
    cols: '6',
    text: 'Participation',
    value: 'participation',
    items: ['Extended day', 'Full day', 'Half day'],
  },
  {
    cols: '6',
    text: 'School year',
    value: 'school_year',
    items: ['2023-2024'],
  },
]);

const calculatorInputs = ref({
  family_size: null,
  income: null,
  participation: null,
  school_year: null,
  saved: false,
});

const dispatcher = ref(null);
const matchListing = ref(null);
const mapChanged = ref(false);
const mapChangedLock = ref(true);
const mapChangedProcessing = ref(false);
const previouslySelectedIndex = ref(null);
const selectedIndex = ref(null);
const zoom = ref(7);
const resultsMode = ref('list');
const resultsPreviousMode = ref('list');

const mapRef = ref(null);
const calculatorDialog = ref(null);

const displayListFab = computed(() => {
  return (
    resultsMode.value === 'list' ||
    (resultsMode.value === 'provider' && resultsPreviousMode.value === 'list')
  );
});

const mapTop = computed(() => {
  if (store.state.pages.Search.features.enable_filters) {
    return 60 + props.top;
  }
  return props.top;
});

const listStyle = computed(() => {
  if (resultsMode.value === 'provider') {
    return 'display: none;';
  }
  if (display.mdAndUp.value) {
    return `height: 100%; width: 50%; overflow-y: auto; padding-top: ${mapTop.value}px;`;
  }
  if (resultsMode.value === 'list') {
    return `width: 100%; overflow-y: scroll; height: 100%; padding-top: ${mapTop.value}px;`;
  }
  return 'display: none;';
});

const mapStyle = computed(() => {
  if (display.mdAndUp.value) {
    return `height: 100%; width: 50%; text-align: right; padding-top: ${mapTop.value}px;`;
  }
  if (resultsMode.value === 'map') {
    return `width: 100%; overflow-y: scroll; height: 100%; text-align: right; padding-top: ${mapTop.value}px;`;
  }
  return 'display: none;';
});

const mapCenter = computed(() => {
  if (props.search && props.search.center) {
    return new Coord(props.search.center);
  }
  return props.defaultCenter;
});

const mapDestination = computed(() => {
  if (props.search && props.search.destination && props.search.destination.latitude) {
    return new Coord(props.search.destination);
  }
  return null;
});

const mapOrigin = computed(() => {
  if (props.search && props.search.origin && props.search.origin.latitude) {
    return new Coord(props.search.origin);
  }
  return null;
});

const providerClass = computed(() => {
  const topPadding = store.state.pages.Search.features.enable_filters
    ? 60 + props.top
    : 0 + props.top;
  if (resultsMode.value !== 'provider') {
    return 'display: none;';
  }
  if (display.mdAndUp.value) {
    return `height: 100%; width: 50%; overflow-y: auto; padding-top: ${topPadding}px;`;
  }
  return `width: 100%; overflow-y: scroll; height: 100%; padding-top: ${topPadding}px;`;
});

const redoSearchButtonStyle = computed(() => {
  const style = ['position: absolute'];
  style.push(`top: ${27 + mapTop.value}px`);
  style.push('z-index: 1');
  style.push('margin-right: 20px');
  if (isRtl) {
    style.push('right: 20px');
  } else {
    style.push('left: 20px');
  }
  return style.join(';');
});

const schema = computed(() => {
  if (props.search?.schema_id) {
    return store.state.schemas[props.search.schema_id];
  }
  return null;
});

const selectedMatch = computed(() => {
  if (selectedIndex.value == null) return null;
  return props.matches[selectedIndex.value];
});

const showRedoSearchHere = computed(() => {
  return mapChanged.value && (resultsMode.value === 'map' || display.mdAndUp.value);
});

const showStructuredSupportAlert = computed(() => {
  return (
    store.state.pages.Search.structured_support_alert &&
    store.state.pages.Search.structured_support_alert.length > 0
  );
});

onMounted(async () => {
  await apiPromise;
  setMap();
});

const calculateFinancialAid = (newVal) => {
  calculatorInputs.value = newVal;
  calculatorInputs.value.saved = true;
  calculatorDialog.value?.close();
};

const closeProvider = () => {
  previouslySelectedIndex.value = selectedIndex.value;
  selectedIndex.value = null;
  changeMode(resultsPreviousMode.value);
  fitMap();
};

const fitMap = () => {
  mapChanged.value = false;
  mapChangedLock.value = false;
  mapChangedProcessing.value = false;

  if (dispatcher.value) {
    dispatcher.value.clear();
  }

  if (props.search.sw_lat || props.matches.length === 0) {
    return;
  }

  const bounds = new google.maps.LatLngBounds();
  if (mapOrigin.value) {
    bounds.extend(mapOrigin.value);
  }
  if (props.location_type === 'commute' && mapDestination.value) {
    bounds.extend(mapDestination.value);
  }
  for (let i = 0; i < props.matches.length; i += 1) {
    bounds.extend(new Coord(props.matches[i]));
  }
  mapRef.value.map.fitBounds(bounds);
};

const handleMapChanged = () => {
  if (!mapChangedLock.value) {
    mapChanged.value = true;
  }
};

const openCalculator = () => {
  return calculatorDialog.value?.open(JSON.parse(JSON.stringify(calculatorInputs.value)));
};

const redoSearch = () => {
  if (!mapRef.value) return;

  mapChangedProcessing.value = true;
  emit('redo', [
    mapRef.value.map.getBounds().getSouthWest(),
    mapRef.value.map.getBounds().getNorthEast(),
  ]);
};

const select = (index) => {
  selectedIndex.value = index;
  changeMode('provider');

  if (dispatcher.value) {
    dispatcher.value.clear();
    if (mapOrigin.value && mapDestination.value && props.search.location_type === 'commute') {
      dispatcher.value.dispatch(
        mapOrigin.value,
        mapDestination.value,
        props.search.travel_mode,
        [{ location: new Coord(selectedMatch.value), stopover: true }],
        (transitTime) => {
          selectedMatch.value.transit_time = transitTime;
        },
      );
    } else if (mapOrigin.value) {
      dispatcher.value.dispatch(
        mapOrigin.value,
        new Coord(selectedMatch.value),
        props.search.travel_mode,
        [],
        (transitTime) => {
          selectedMatch.value.transit_time = transitTime;
        },
      );
    }
  }
};

const setMap = () => {
  changeMode('list');
  selectedIndex.value = null;
  if (!dispatcher.value) {
    dispatcher.value = new RouteDispatcher(mapRef.value.map);
  } else {
    dispatcher.value.clear();
  }
  fitMap();
};

const changeMode = (newMode) => {
  if (resultsMode.value !== 'provider') {
    resultsPreviousMode.value = resultsMode.value;
  }
  resultsMode.value = newMode;
  if (resultsMode.value !== 'list') {
    store.commit('setExpandAppBarSmAndDown', false);
    if (resultsMode.value === 'map') {
      fitMap();
    }
  }
};

defineExpose({
  changeMode,
  fitMap,
});

watch(
  () => props.matches,
  () => {
    if (props.matches.length > 0) {
      fitMap();
    }
  },
);
</script>

<style scoped>
.custom {
  padding-top: 10px;
}
</style>
