<template>
  <span
    v-t="ranking"
    class="fs-16 fw-600"
    data-cy="selection-order"
    data-testid="selection-order"
  />
</template>

<script setup>
import { ordinalize } from '@/plugins/filters';

const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  rankingSuffix: {
    type: String,
    default: 'choice',
  },
});

const ranking = computed(() => {
  return `${ordinalize(props.index + 1)} ${props.rankingSuffix}`;
});
</script>
