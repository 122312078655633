<template>
  <v-card>
    <v-card-text>
      <div
        v-t="'Search for care near:'"
        class="c-black fs-16 fw-600"
      />
      <v-row>
        <v-col>
          <LocationTypeTabButton
            v-for="locationType in LOCATION_TYPES"
            @click="handleLocationTypeChange(locationType.value)"
            :key="locationType.value"
            :current-value="localValue.location_type"
            :label="locationType.label"
            :value="locationType.value"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="localValue.location_type == 'zip'"
        dense
      >
        <v-col>
          <LocationZipInput
            @change="handleChange('zip', $event)"
            @done="handleChangeAndClose('zip', $event)"
          />
        </v-col>
      </v-row>
      <template v-else>
        <LocationAddressInput
          @change="handleChange('origin', $event)"
          @done="handleChangeAndClose('origin', $event)"
          id="search_origin_address"
          :label="localValue.location_type == 'commute' ? t('From:') : undefinded"
          aria-label="Enter your origin location"
        />
        <LocationAddressInput
          v-if="localValue.location_type == 'commute'"
          @change="handleChange('destination', $event)"
          @done="handleChangeAndClose('destination', $event)"
          id="search_destination_address"
          :label="t('To:')"
          aria-label="Enter your destination location"
        />
      </template>
      <v-row
        v-if="!hideClose"
        class="mt-1 ta-right"
      >
        <v-col>
          <v-btn
            @click="handleDone"
            class="focus-very-visible"
            color="primary"
            data-cy="location_done"
          >
            {{ t('Done') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import LocationAddressInput from '@/shared/components/search/LocationAddressInput.vue';
import LocationTypeTabButton from '@/shared/components/search/LocationTypeTabButton.vue';
import LocationZipInput from '@/shared/components/search/LocationZipInput.vue';

const { t } = useI18n();

const LOCATION_TYPES = [
  { value: 'home', label: 'Home' },
  { value: 'work', label: 'Work' },
  { value: 'zip', label: 'Zip code' },
  { value: 'commute', label: 'Along my commute' },
];

const props = defineProps({
  hideClose: {
    default: false,
    type: Boolean,
  },
  open: {
    default: null,
    type: Boolean,
  },
  search: {
    default: null,
    type: Object,
  },
});

const localValue = ref(props.search);

const emit = defineEmits(['change', 'update']);

function handleDone() {
  emit('update', localValue.value);
}

function handleLocationTypeChange(locationType) {
  localValue.value.location_type = locationType;
}

function handleChange(field, value) {
  if (field === 'zip') {
    if (value.length === 5) {
      localValue.value.zip = value;
      localValue.value.formatted_origin = value;
    }
  } else {
    if (value.value) {
      localValue.value[field] = { place_id: value.id };
      localValue.value[`formatted_${field}`] = value.value;
    } else {
      localValue.value[field] = null;
      localValue.value[`formatted_${field}`] = null;
    }
  }

  if (validChange()) {
    emit('change', localValue.value);
  }
}

function handleChangeAndClose(field, value) {
  handleChange(field, value);
  emit('update', localValue.value);
}

function validChange() {
  if (localValue.value.location_type === 'zip') {
    return localValue.value.zip && localValue.value.zip.length === 5;
  }
  if (localValue.value.location_type === 'commute') {
    return (
      localValue.value.origin &&
      localValue.value.destination &&
      localValue.value.formatted_destination &&
      localValue.value.formatted_origin
    );
  }
  return localValue.value.origin && localValue.value.formatted_origin;
}
</script>
