<template>
  <ResourceDialog
    @save="openDownload"
    ref="dialog"
    :processing="processing"
    :save-button-disabled="disabled"
    save-button-text="Download"
    title="Download search results"
  >
    <template #form>
      <v-row dense="">
        <v-col
          v-if="lists && lists.length == 0"
          cols="12"
        >
          <div
            v-t="'Save providers to your favorite lists so you can download them.'"
            class="fs-16 c-black"
          />
        </v-col>
        <v-col
          v-for="(list, index) in lists"
          :key="index"
          cols="12"
        >
          <v-checkbox
            v-model="list.selected"
            :label="list.name"
            class="mt-0"
            data-cy="favorites-list-check"
            false-icon="check_box_outline_blank"
            hide-details=""
            true-icon="check_box"
          />
        </v-col>
      </v-row>
      <div v-if="lists && lists.length > 0">
        <v-row class="mb-2 mt-4">
          <v-col class="py-0 c-black fs-16">
            {{ $t('Download format') }}
          </v-col>
        </v-row>
        <v-row
          class="mb-3"
          dense
        >
          <v-col>
            <v-btn-toggle
              v-model="format"
              color="primary"
            >
              <v-btn
                :ripple="false"
                value="csv"
              >
                {{ $t('CSV') }}
              </v-btn>
              <v-btn
                :ripple="false"
                value="pdf"
              >
                {{ $t('PDF') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row
          class="mb-2 mt-4"
          dense
        >
          <v-col>
            <div
              v-t="'Language'"
              class="py-0 c-black fs-16 mb-2"
            />
            <div class="bc-body-md">
              <v-select
                v-model="locale"
                :items="$store.state.languages"
                aria-label="Language options"
                variant="filled"
                hide-details
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </ResourceDialog>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Download',

  components: {
    ResourceDialog,
  },

  props: {
    lists: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      format: null,
      locale: this.$i18n.locale,
      processing: false,
    };
  },
  computed: {
    disabled() {
      return !this.format || this.selected.length < 1;
    },

    selected() {
      return this.lists.filter((list) => list.selected).map((list) => list.id);
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function (newVal) {
      this.locale = newVal;
    },
  },

  methods: {
    open() {
      this.processing = false;
      this.$refs.dialog.open();
    },

    openDownload() {
      this.processing = true;
      const params = {
        format: this.format,
        group_id: this.$route.query.group_id,
        lists: this.selected.join(','),
        locale: this.locale,
      };
      Api.downloadListsUrl(params);
      this.$refs.dialog.close();
    },
  },
};
</script>
