<template>
  <v-container
    v-if="schema"
    class="mxw-800"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-text class="py-0 mnh-250">
        <template v-if="questions.length > 0">
          <QuestionSet
            v-model="survey"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :reversible="index > 0"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { locale } = useI18n();

const index = ref(0);
const processing = ref(false);
const schema = ref(null);
const schemaId = computed(() => schema.value?.id);
const section = ref(null);
const survey = ref({ custom: {} });
const transitionName = ref('slide-left');

const { loadQuestions, questions, validQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: schema,
});

watch(
  () => route.params.schemaId,
  () => load(),
  { immediate: true },
);

onMounted(() => load());

function backFromQuestion(index) {
  processing.value = true;

  if (index - 1 < 0) {
    processing.value = false;
  } else {
    goToQuestion(index - 1);
  }
}

async function forwardFromQuestion(index) {
  processing.value = true;

  if (index + 1 >= validQuestions.value.length) {
    await submitSurvey();
  } else {
    goToQuestion(index + 1);
  }
}

function goToQuestion(newIndex) {
  processing.value = false;
  section.value = null;
  index.value = newIndex;

  setTimeout(() => {
    section.value = `question-${newIndex}`;
  }, 600);
}

async function load() {
  await loadSchema();
  await loadQuestions();

  const memberParams = {
    default_locale: locale.value,
    is_anonymous: true,
    organization_id: window.organization_id,
    is_parent: true,
  };

  const resp = await Api.member.create(memberParams).catch((error) => eventBus.error(error));

  if (resp) section.value = 'question-0';
}

async function loadSchema() {
  const resp = await Api.public_api.organization.schema
    .get(route.params.schemaId)
    .catch(() => eventBus.longChime('Survey could not be found'));

  if (!resp) return;

  schema.value = resp.data;
}

async function submitSurvey() {
  survey.value.schema_id = schema.value.id;

  const resp = await Api.parent.survey.create(survey.value).catch((error) => eventBus.error(error));

  processing.value = false;
  if (!resp) return;

  processing.value = false;

  if (schema.value.meta.submitted_redirect_url)
    window.location.replace(schema.value.meta.submitted_redirect_url);
  else router.push({ name: 'Dashboard' });
}
</script>
